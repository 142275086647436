import code from "../common/code";

// all4ace507.iptime.org:12232
function addInitLayer() {
  map.addLayer({
    id: "tl_spbd_buld",
    type: "fill-extrusion",
    minzoom: 13,
    maxzoom: 20,
    source: {
      type: "vector",
      tiles: [
        `http://${code.geoserverIP}/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=KICT:tl_spbd_buld&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      bounds: [
        126.36381402141426, 36.89148678883987, 127.82562229923616,
        38.17522139418934,
      ],
    },
    "source-layer": "tl_spbd_buld",
    paint: {
      "fill-extrusion-color": "#cdcdcd",
      "fill-extrusion-height": ["*", ["to-number", ["get", "gro_flo_co"]], 3],
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "visible" },
  });
  map.setFilter("tl_spbd_buld", [
    "!in",
    "bd_mgt_sn",
    "4128710400123110001004201",
    "4128710400123110001012494",
    "4128710400123110001012497",
    "4128710400123110001012222",
    "4128710400123110001012498",
    "4128710400123110001012223",
    "4128710400123110001012221",
    "4128710400123110001012492",
    "4128710400123110001012499",
    "4128710400123110001012491",
    "4128710400123110001012493",
    "4128710400123110000012292",
    "4128710400123110000012298",
    "4128710400123110000012293",
    "4128710400123110000012295",
    "4128710400123110000004200",
    "4128710400123110000012297",
    "4128710400123110000012504",
    "4128710400123110000012294",
  ]);

  map.addLayer({
    id: "tb_engy_bld_yy_re",
    type: "fill-extrusion",
    minzoom: 6,

    source: {
      type: "vector",
      tiles: [
        `http://${code.geoserverIP}/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&cql_filter=use_yy='2019'&LAYER=KICT:tb_engy_bld_yy_re&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      bounds: [
        126.76718294907049, 37.43008165268006, 127.2229276778434,
        37.69922823006717,
      ],
    },
    "source-layer": "tb_engy_bld_yy_re",
    filter: ["==", "use_yy", "2017"],
    paint: {
      "fill-extrusion-color": [
        "case",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.89],
        "#81001F",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.6],
        "#A41727",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.4],
        "#D63229",
        [">=", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E17976",
        ["<", ["to-number", ["get", "all_all_qty_perc"]], 0.11],
        "#E7B9BB",
        "#cdcdcd",
      ],
      "fill-extrusion-height": ["*", ["to-number", ["get", "gro_flo_co"]], 3],
      "fill-extrusion-base": 0,
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "visible" },
  });
  map.addLayer({
    id: "tb_engy_2017_11_eb",
    type: "fill-extrusion",
    minzoom: 6,

    source: {
      type: "vector",
      tiles: [
        `http://${code.geoserverIP}/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&LAYER=KICT:tb_engy_2017_11_eb&STYLE=&TILEMATRIX=EPSG:3857:{z}&TILEMATRIXSET=EPSG:3857&FORMAT=application/vnd.mapbox-vector-tile&TILECOL={x}&TILEROW={y}`,
      ],
      bounds: [
        126.36381402141426, 36.89148678883987, 127.82562229923616,
        38.17522139418934,
      ],
    },
    "source-layer": "tb_engy_2017_11_eb",
    paint: {

      "fill-extrusion-color": [
        "case",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.89],
        "#81001F",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.6],
        "#A41727",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.4],
        "#D63229",
        [">=", ["to-number", ["get", "use_qty_perc"]], 0.11],
        "#E17976",
        ["<", ["to-number", ["get", "use_qty_perc"]], 0.11],
        "#E7B9BB",
        "#cdcdcd",
      ],
      "fill-extrusion-height": ["*", ["to-number", ["get", "flr_no"]], 3],
      "fill-extrusion-base": [
        "-",
        ["*", ["to-number", ["get", "flr_no"]], 3],
        3,
      ],
      "fill-extrusion-opacity": 1,
    },
    layout: { visibility: "none" },
  });

  Object.keys(code.kictFloor).forEach((key) => {
    const totalFloor = code.kictFloor[key];
    for (let i = 1; i <= totalFloor; i++) {
      map.addSource(`${key}_${i}`, {
        type: "geojson",
        data: `data/kictLayer/${key}.json`,
      });
      map.addLayer({
        id: `${key}_${i}`,
        type: "fill-extrusion",
        source: `${key}_${i}`,
        minzoom: 6,
        layout: { visibility: "none" },
        paint: {
          "fill-extrusion-color": "#cdcdcd",
          "fill-extrusion-height": i * 3,
          "fill-extrusion-base": i * 3 - 3,
          "fill-extrusion-opacity": 1,
        },
      });
    }
  })
}

function addRasterLayer() {
  map.addLayer({
    id: "ilsan_raster",
    type: "raster",
    source: {
      type: "raster",
      tiles: [
        `http://${code.geoserverIP}/geoserver/gwc/service/wmts?layer=KICT%3Ailsan_raster_3cm&style=&tilematrixset=EPSG%3A3857&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=EPSG%3A3857%3A{z}&TileCol={x}&TileRow={y}`,
      ],
      bounds: [
        126.73620857193194, 37.66705285988249, 126.74368368588439,
        37.67424908708122,
      ],
      minzoom: 13,
      maxzoom: 21,
      tileSize: 512,
    },
  });
}

const exports = {
  init() {
    addInitLayer();
  },
  addRaster() {
    addRasterLayer();
  }
};

export default exports;
