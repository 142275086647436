<template>
  <div>
    {{ this.meterType == "1" ? '＊일반표제부와 매칭된 계량기 현황' : '＊총괄표제부와 매칭된 계량기 현황' }}
    <b-table
      class="table-style"
      striped
      :items="items"
      :fields="fields"
      responsive="sm"
    />
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
    meterType: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'rownum', label: 'No.',
        },
        {
          key: 'engy_esncno', label: '계량기 고객번호',
        },
        {
          key: 'engy_nm', label: '에너지원 구분',
        },
        {
          key: 'use_purps_cd', label: '용도',
        },
        {
          key: 'engy_sply_kik_cd', label: '공급기관',
        }

      ],
      items: [],
    }
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.$store.dispatch("main/FETCH_ENERGY_METER_INFO_DATA", {
      dataCode: "energyMeter",
      meterType: this.meterType, // 1: 일반표제부 계량기, 2: 총괄표제부 계량기
      mgmBldPk: this.mgmBldPk
    }).then(() => {
      this.items = this.$store.state.main.meterList;
    }).catch(() => {

    })
  }

}
</script>

<style scoped>
.body-style {

  font-family: Helvetica Neue, Arial, sans-serif;
}

.table-style {
  position: relative;
  white-space:nowrap;
}
</style>
