<template>
  <div>

    <b-table
      responsive="sm"
      striped
      :fields="fields"
      :items="buildFloorData.map(v => ({
        flrgbnm: v.flrgbnm,
        flrnonm: v.flrnonm,
        mainpurpsnm: v.mainpurpsnm,
        etcpurps: v.etcpurps,
        area: v.area,
      }))"
    />
  </div>
</template>
<script>
import { BTable } from 'bootstrap-vue'
import { mapGetters } from "vuex";

export default {
  components: {
    BTable
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'flrgbnm', label: '층구분',
        },
        {
          key: 'flrnonm', label: '층번호',
        },
        {
          key: 'mainpurpsnm', label: '층용도',
        },
        {
          key: 'etcpurps', label: '층 기타용도',
        },
        {
          key: 'area', label: '연면적(㎡)',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      buildFloorData: "main/getBuildFloorData",
    }),
  },
  created() {
    // 페이지 생성 시 데이터 불러옴
    this.$store.dispatch("main/FETCH_BUILD_FLOOR_DATA", {
      dataCode: "buildFloorData",
      mgmBldPk: this.mgmBldPk
    });
  }
}
</script>
<style scoped>
/*.row-style{
  border: 1px solid #cccccc;
  border-radius: 3px;
  font-family: Helvetica Neue, Arial, sans-serif;
}*/
</style>
