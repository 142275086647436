import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";

let rayPoint;
let par;
let groupName;
let gmlList = ['100010', '100020', '100030', '100080', '100090', '100100', '100110', '100120', '100130', '100160', '100200', '100230', '100260'];
var modelArray = [
  "100010", "100020", "100030", "100040", "100050",
  "100060", "100080", "100090", "100100", "100110",
  "100120", "100130", "100140", "100150", "100160",
  "100180", "100200", "100210", "100220", "100230",
  "100240", "100250", "100260",
];



var customLayer = {
  id: "3d-model",
  type: "custom",
  renderingMode: "3d",
  onAdd: function (map, gl) {
    this.camera = new THREE.Camera();
    window.scene = new THREE.Scene();

    var light = new THREE.AmbientLight(0xffffff, 1.5);
    scene.add(light);

    var loader = new GLTFLoader();
    var group = new THREE.Group();

    for (let i = 0; i < modelArray.length; i++) {
      let modelName = modelArray[i];
      loader.load(
        "data/building/" + modelArray[i] + ".glb",
        function (gltf) {
          gltf.scene.name = modelName;

          group.add(gltf.scene);
        }.bind(this)
      );
    }
    loader.load(
      "data/building/100151.glb",
      function (gltf) {
        gltf.scene.name = "kict15";
        window.kict15 = gltf.scene;
        //scene.add(gltf.scene);
        group.add(gltf.scene);
      }.bind(this)
    );

    group.name = "building";
    scene.add(group);

    this.map = map;

    this.renderer = new THREE.WebGLRenderer({
      canvas: map.getCanvas(),
      context: gl,
      antialias: true,
    });

    this.renderer.autoClear = false;

    this.raycaster = new THREE.Raycaster();
    this.raycaster.near = -1;
    this.raycaster.far = 1e6;
  },
  render: function (gl, matrix) {
    let modelAsMercatorCoordinate = window.mapboxgl.MercatorCoordinate.fromLngLat(
      [126.73989951790394, 37.67094986040459],
      0
    );
    let modelTransform = {
      translateX: modelAsMercatorCoordinate.x,
      translateY: modelAsMercatorCoordinate.y,
      translateZ: modelAsMercatorCoordinate.z,
      rotateX: Math.PI / 2,
      rotateY: 0,
      rotateZ: 0,
      scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
    };
    var rotationX = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(1, 0, 0),
      modelTransform.rotateX
    );
    var rotationY = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 1, 0),
      modelTransform.rotateY
    );
    var rotationZ = new THREE.Matrix4().makeRotationAxis(
      new THREE.Vector3(0, 0, 1),
      modelTransform.rotateZ
    );

    var m = new THREE.Matrix4().fromArray(matrix);
    var l = new THREE.Matrix4()
      .makeTranslation(
        modelTransform.translateX,
        modelTransform.translateY,
        modelTransform.translateZ
      )
      .scale(
        new THREE.Vector3(
          modelTransform.scale,
          -modelTransform.scale,
          modelTransform.scale
        )
      )
      .multiply(rotationX)
      .multiply(rotationY)
      .multiply(rotationZ);

    this.camera.projectionMatrix.elements = matrix;
    this.camera.projectionMatrix = m.multiply(l);
    this.renderer.resetState();
    this.renderer.render(scene, this.camera);
    this.map.triggerRepaint();
  },
  raycast: function (point, isClick) {
    var mouse = new THREE.Vector2();
    // // scale mouse pixel position to a percentage of the screen's width and height
    mouse.x = (point.x / this.map.transform.width) * 2 - 1;
    mouse.y = 1 - (point.y / this.map.transform.height) * 2;
    // const camInverseProjection = new THREE.Matrix4().getInverse(
    //   this.camera.projectionMatrix
    // );
    const camInverseProjection = this.camera.projectionMatrix.clone().invert();
    const cameraPosition = new THREE.Vector3().applyMatrix4(
      camInverseProjection
    );
    const mousePosition = new THREE.Vector3(mouse.x, mouse.y, 1).applyMatrix4(
      camInverseProjection
    );
    const viewDirection = mousePosition.clone().sub(cameraPosition).normalize();

    this.raycaster.set(cameraPosition, viewDirection);

    // calculate objects intersecting the picking ray
    var intersects = this.raycaster.intersectObjects(scene.children[1].children, true);
    if (intersects.length > 0) {
      par = intersects[0].object.parent;
      while (!par.name.includes("kmz")) {
        par = par.parent;
      }
      groupName = par.name;
      let kictModelName;
      gmlList.forEach(e => {
        if (groupName.includes(e)) kictModelName = e;
      })
      // if(groupName.includes("100010")) {
      //   kictModelName = "100010";
      // } else if (groupName.includes("100020")){
      //   kictModelName = "100020";
      // }
      
      if (gmlList.includes(kictModelName)) {
        $(".btn_3dview").show();
        $(".btn_3dview").css({ left: point.x + 10, top: point.y });
        if(kictModelName === '100260') kictModelName = '100240' 
        $(".btn_3dview li").attr("value", kictModelName);
      } else {
        $(".btn_3dview").hide();
      }
    } else {
      $(".btn_3dview").hide();
    }
    //console.log("intersects",intersects);
    //console.log(scene.children);
    if (intersects.length) {
      rayPoint = { x: 0, y: 0, z: 0 };
      rayPoint.x = intersects[0].point.x;
      rayPoint.y = intersects[0].point.y;
      rayPoint.z = intersects[0].point.z;
    } else {
      rayPoint = 0;
    }
  },
};

let bimArr = { "3d-model": customLayer };

export default bimArr;
