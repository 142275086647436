<template>
  <div>
    <div class="text-right">
      <!-- 필터 / 지도 -->
      <service-button
        :current="'energy'"
        class="position-absolute mt-1 mr-5 service-button"
      />
    </div>
    <!-- 검색  -->
    <div class="input-search">
      <input-search-component :input-placeholder="inputPlaceholder" />
    </div>

    <!--  에너지 소비랑  -->
    <div class="select-use">
      <select-use-component />
    </div>
    <!--  주용도 검색  -->
    <div

      class="main-purps"
    >
      <main-purps-component />
    </div>
    <!--지도 표현 -->
    <MapGisComponent />
  </div>
</template>

<script>
import ServiceButton from "@/layouts/button/ServiceButton.vue";
import InputSearchComponent from "@/views/building/InputSearchComponent";
import SelectUseComponent from "@/views/building/SelectUseComponent";
import MainPurpsComponent from "@/views/building/MainPurpsComponent";
import MapGisComponent from "@/views/building/MapGisComponent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    ServiceButton,
    InputSearchComponent,
    SelectUseComponent,
    MainPurpsComponent,
    MapGisComponent,
  },
  props: {},
  computed: {
    ...mapGetters({
      engyUseChange: "main/getEngyUseChange",
    }),
  },
  data() {
    return {
      inputPlaceholder: '동단위로 검색해주세요',
    };
  },
  methods: {},
};
</script>
<style scoped>
.service-button {
  top: 0px;
  right: 0px;
  z-index: 10;
  margin-right: 20px !important;
}

.input-search {
  position: absolute;
  z-index: 2;
  background: white;
  left: 20px;
  top: 80px;
  width: 300px;
  height: 135px;
  padding: 10px;
  border: solid #6e6b7b;
  border-radius: 15px;
}

.select-use {
  position: absolute;
  z-index: 1;
  background: white;
  left: 20px;
  width: 300px;
  padding: 20px;
  border: solid #6e6b7b;
  border-radius: 15px;
  top: 216px;
}

.main-purps {
  position: absolute;
  z-index: 1;
  background: white;
  left: 20px;
  width: 300px;
  padding: 20px;
  border: solid #6e6b7b;
  border-radius: 30px;
  top: 711px;
}
</style>
