<template>
  <div>
    <energy-use-sub-info-component
      :mgm-bld-pk="mgmBldPk"
    />
  </div>
</template>
<script>
import EnergyUseSubInfoComponent from "@/views/building/EnergyUse/EnergyUseSubInfoComponent";

export default {
  components: {
    EnergyUseSubInfoComponent
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      regstrKindCd: '', // 1: 총굘표제부, 2: 일반표제부, 3: 표제부
    }
  }
}
</script>
