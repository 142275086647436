<template>
  <div>
    <h3>▶주용도 검색</h3>
    <b-row>
      <b-col>

        <b-form-group
          label="주용도"
          label-for="mainPurpsCd"
        >
          <v-select
            id="mainPurpsCd"
            v-model="mainSelected.MAIN_PURPS_CD"
            multiple
            :disabled="engyUseChange"
            label="cdNm"
            placeholder="선택"
            :options="commCodeData.MAIN_PURPS_CD"
            @input="onChageMainPurpsCd($event)"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BFormGroup, } from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters } from "vuex";
import Ripple from 'vue-ripple-directive';
export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      commCodeData: "main/getCommCodeData",
      mainSelected: "main/getSelectedCodeData",
      engyUseChange: "main/getEngyUseChange",
    }),
    checkPurpsCd() {
      return this.mainSelected.MAIN_PURPS_CD;
    },
  },

  data() {
    return {
      commonEnergy: {},
      purpsArr: [],
      cdCnt: 1
    }
  },
  watch: {
    checkPurpsCd(val) {
      if (this.cdCnt >= 3) {
        this.purpsArr = [];

        if (val) {
          val.forEach(item => {
            const temp = {};
            temp.prupsCd = item.cdId;

            if (!this.purpsArr.includes(item.cdId)) {
              this.purpsArr.push(item.cdId);
            }

            this.commonEnergy.useYyCd = this.mainSelected.useYyCd.cdId;
            this.commonEnergy.engyKindCd = this.mainSelected.engyKindCd.cdId;
            this.commonEnergy.clsfKindCd = this.mainSelected.clsfKindCd.cdId;
            this.commonEnergy.engyAllQtyCd = this.mainSelected.engyAllQtyCd.cdId;
            this.commonEnergy.engyUse = this.mainSelected.engyUse.cdId;

            this.commonEnergy.prupsCd = this.purpsArr
          });
          // console.log(JSON.stringify(this.commonEnergy));
          gis.search.changeEnergyFilter(this.commonEnergy);
        }
      }
      // eslint-disable-next-line no-plusplus
      this.cdCnt++;
    },

  },
  created() {
    // 공통코드 불러오기
    this.$store.dispatch("main/FETCH_COMM_CODE_DATA");
  },
  mounted() {

  },
  methods: {
    // 주용도 검색값 추가
    onChageMainPurpsCd(event) {
      if (event.length !== 0) {
        this.$store.dispatch("main/FETCH_SELECT_PURPS_DATA", this.mainSelected.MAIN_PURPS_CD);

        /* if (event) {
          this.commonEnergy.useYyCd = this.mainSelected.useYyCd.cdId;
          this.commonEnergy.engyKindCd = this.mainSelected.engyKindCd.cdId;
          this.commonEnergy.clsfKindCd = this.mainSelected.clsfKindCd.cdId;
          this.commonEnergy.engyAllQtyCd = this.mainSelected.engyAllQtyCd.cdId;
          this.commonEnergy.engyUse = this.mainSelected.engyUse.cdId;

          if (this.mainSelected.MAIN_PURPS_CD) {
            this.mainSelected.MAIN_PURPS_CD.forEach(item => {
              const temp = {};
              temp.prupsCd = item.cdId;

              if (!this.purpsArr.includes(item.cdId)) {
                this.purpsArr.push(item.cdId);
              }
              this.commonEnergy.prupsCd = this.purpsArr
            });
          }

          console.log(JSON.stringify(this.commonEnergy))
        } */
      } else {
        // alert('sss');
        this.commonEnergy.prupsCd = null

        // console.log(JSON.stringify(this.commonEnergy))
      }
    },
  }
}
</script>
