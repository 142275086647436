function isLocalNetwork(hostname = window.location.hostname) {
  return (
    (['localhost', '127.0.0.1', '', '::1'].includes(hostname))
    || (hostname.startsWith('all4ace507.iptime.org'))
    || (hostname.startsWith('192.168.'))
  )
}

const exports = new Object();

if (isLocalNetwork()) {
  exports.geoserverIP = "52.78.238.167:12230";
} else {
  exports.geoserverIP = "52.78.238.167:12230";
}

exports.buildingCode = {
  bldg_id: "건물ID",
  bldg_name: "건물명",
  ground_addr: "대지위치",
  jibun: "지번",
  address: "도로명주소",
  ground_area: "대지면적",
  gross_floor_area: "연면적",
  jiyeok: "지역",
  guyeok: "구역",
  bldg_area: "건축면적",
  real_gross_floor_area: "용적률산정용연면적",
  main_structure: "주구조",
  main_use: "주용도",
  underground_floor_cnt: "지하층수",
  ground_floor_cnt: "지상층수",
  bldg_coverage_ratio: "건폐율",
  floor_area_ratio: "용적률",
  elevation: "높이",
  roof_type: "지붕",
  bldg_owner_name: "건축주 성명",
  bldg_owner_num: "건축주 등록번호",
  architect_name: "설계자",
  architect_num: "설계자 면허번호",
  builder_name: "공사시공자(현장관리인)",
  builder_num: "공사시공자 면허번호",
  water_treatment_type: "오수정화시설 형식",
  accept_date: "허가일",
  build_start_date: "착공일",
  completion_date: "사용승인일",
};

exports.floorCode = {
  bldg_id: "건물ID",
  floor_id: "층ID",
  floor_name: "층 명칭",
  floor_structure: "층 구조",
  floor_usage: "층 용도",
  floor_area: "층 면적",
};

exports.spaceCode = {
  bldg_id: "건물ID",
  floor_id: "층ID",
  space_id: "공간ID",
  space_name: "공간명",
};

exports.sensorCode = {
  floor_id: "층ID",
  space_id: "공간ID",
  device_seq: "장치일련번호",
  device_nm: "장치명",
  tag_id: "태그ID",
  instl_height: "설치 높이",
  device_desc: "장치정보",
  device_unique_id: "장치고유ID",
  register: "등록자",
  regst_dt: "등록일시",
  updt_dt: "수정일시",
};

exports.floorPosition = {
  b01b01_bs_rm: 28,
  b02b02_bs_rm: 24.5,
  f01f01_bs_rm: 31.5,
  f02f02_bs_rm: 35.5,
  f03f03_bs_rm: 39.5,
  f04f04_bs_rm: 43.5,
  f05f05_bs_rm: 49,
};

exports.nodeColor = { startNode: 0x00b952, endNode: 0xff0000 };

exports.SafetyColor = {
  A: "#418610",
  B: "#92D050",
  C1: "#FFFF00",
  C2: "#FFD900",
  D: "#EA7C16",
  E: "#FF0000",
};

exports.sensorLegend = {
  battery: "#FF0000",
  obscuration: "#3cb7ff",
  motionDetect: "#418610",
  doorCheck: "#418610",
  pm10_1: "#FFFF00",
  pm10_2: "#EA7C16",
  pm10_3: "#FF0000",
  pm25_1: "#FFFF00",
  pm25_2: "#EA7C16",
  pm25_3: "#FF0000",
  heat_1: "#FFFF00",
  heat_2: "#FFD900",
  heat_3: "#EA7C16",
  heat_4: "#FF0000",
};

exports.bldColorObject = {
  All_All_01: "all_all_qty_perc",
  All_Eb_01: "all_base_qty_perc",
  All_Ec_01: "all_acon_qty_perc",
  All_Eh_01: "all_hetg_qty_perc",
  "11_All_01": "elec_all_qty_perc",
  "12_All_01": "gas_all_qty_perc",
  "13_All_01": "hetg_all_qty_perc",

  All_All_02: "all_all_1st_perc",
  All_Eb_02: "all_base_1st_perc",
  All_Ec_02: "all_acon_1st_perc",
  All_Eh_02: "all_hetg_1st_perc",
  "11_All_02": "elec_all_1st_perc",
  "12_All_02": "gas_all_1st_perc",
  "13_All_02": "hetg_all_1st_perc",

  All_All_03: "all_all_co2_perc",
  All_Eb_03: "all_base_co2_perc",
  All_Ec_03: "all_acon_co2_perc",
  All_Eh_03: "all_hetg_co2_perc",
  "11_All_03": "elec_all_co2_perc",
  "12_All_03": "gas_all_co2_perc",
  "13_All_03": "hetg_all_co2_perc",

  "11_Eb_01": "elec_base_qty_perc",
  "11_Ec_01": "elec_acon_qty_perc",
  "11_Eh_01": "elec_hetg_qty_perc",
  "12_Eb_01": "gas_base_qty_perc",
  "12_Ec_01": "gas_acon_qty_perc",
  "12_Eh_01": "gas_hetg_qty_perc",
  "13_Eb_01": "hetg_base_qty_perc",
  "13_Ec_01": "hetg_acon_qty_perc",
  "13_Eh_01": "hetg_hetg_qty_perc",

  "11_Eb_02": "elec_base_1st_perc",
  "11_Ec_02": "elec_acon_1st_perc",
  "11_Eh_02": "elec_hetg_1st_perc",
  "12_Eb_02": "gas_base_1st_perc",
  "12_Ec_02": "gas_acon_1st_perc",
  "12_Eh_02": "gas_hetg_1st_perc",
  "13_Eb_02": "hetg_base_1st_perc",
  "13_Ec_02": "hetg_acon_1st_perc",
  "13_Eh_02": "hetg_hetg_1st_perc",

  "11_Eb_03": "elec_base_co2_perc",
  "11_Ec_03": "elec_acon_co2_perc",
  "11_Eh_03": "elec_hetg_co2_perc",
  "12_Eb_03": "gas_base_co2_perc",
  "12_Ec_03": "gas_acon_co2_perc",
  "12_Eh_03": "gas_hetg_co2_perc",
  "13_Eb_03": "hetg_base_co2_perc",
  "13_Ec_03": "hetg_acon_co2_perc",
  "13_Eh_03": "hetg_hetg_co2_perc",
}

exports.modelOrigin = [126.73989951790394, 37.67094986040459];

exports.kictFloor = {
  kict01: 5,
  kict02: 5,
  kict03: 3,
  kict08: 8,
  kict09: 2,
  kict10: 2,
  kict11: 1,
  kict12: 1,
  kict13: 2,
  kict16: 2,
  kict20: 3,
  kict23: 3,
  kict24: 1,
  kict98: 4,
  kict99: 6
};

exports.satisfactionColor = {
  1: "#ff0000",
  2: "#ff0000",
  3: "#ff0000",
  4: "#FFFF00",
  5: "#008000",
  6: "#008000",
  7: "#008000",
};

exports.satisfactionData = {
  "1-1.": "업무공간에서 개별업무 및 사품보관 등에 사용되는 공간의 양에 대해서 얼마나 만족하십니까?",
  "1-2.": "업무공간의 시각적인 프라이버시에 대해서 얼마나 만족하십니까?",
  "1-3.": "업무공간에서 직장동료들과의 상호소통의 용이한 정도에 대해서 얼마나 만족하십니까?",
  "2-1.": "업무공간의 전반적인 온도에 얼마나 만족하십니까?",
  "2-2.": "업무공간의 전반적인 습도에 얼마나 만족하십니까?",
  "3-1.": "업무공간의 실내공기 신선도가 쾌적한 정도에 대해서 얼마나 만족하십니까?",
  "3-2.": "업무공간의 실내공기 냄새가 쾌적한 정도에 대해서 얼마나 만족하십니까?",
  "4-1.": "업무공간의 인공조명의 정도에 대해서 얼마나 만족하십니까?",
  "4-2.": "업무공간의 자연채광의 정도에 대해서 얼마나 만족하십니까?",
  "4-3.": "업무공간의 조명의 시각적인 편안감(눈부심, 반사, 대조도 등)에 대해서 얼마나 만족하십니까?",
  "5-1.": "업무공간의 소음도(dB) 정도에 대해서 얼마나 만족하십니까?",
  "5-2.": "업무공간 내 대화의 프라이버시 보장 정도에 대해서 얼마나 만족하십니까?",
  "6-1.": "모든 것을 고려하여 개인 업무공간에 얼마나 만족하십니까?",
  "6-2.": "이 건물의 환경 조건(예 : 열, 공기질, 조명, 소음, 사무실 공간 배치)에 따라 업무능력이 어느정도 향상 또는 저하되었는지 추정해주십시오.",
}
export default exports;
