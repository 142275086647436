<template>
  <b-table
    striped
    responsive
    :items="items"
    :fields="fields"
    class="row-modal"
  />
</template>

<script>
import { BTable } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'opnsvcnm', label: '업종명',
        },
        {
          key: 'bplcnm', label: '상호명',
        },
        {
          key: 'sitewhladdr', label: '인허가 주소',
        },
        {
          key: 'uptaenm', label: '업태',
        },
      ],
      items: [],
    }
  },
  created() {
    // 인허가 정보 데이터
    this.$store.dispatch("main/FETCH_BUILD_LICENSE_DATA", {
      dataCode: "buildLicenseData",
      mgmBldPk: this.mgmBldPk
    }).then(() => {
      this.items = this.$store.state.main.licenseList;
    }).catch(() => {
      console.error('오류')
    })
  }
}

</script>
<style lang="scss">
.row-modal {
  font-family: Helvetica Neue, Arial, sans-serif;
}
</style>
