<template>
  <div>
    <b-card>
      <h5>에너지원별 에너지 사용량</h5>
      <app-echart-bar
        :option-data="option"
      />
    </b-card>
  </div>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import { mapGetters } from "vuex";

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
    energyChartType: {
      type: String,
      required: true,
    },
    useYySelected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      option: {
        grid: {
          // top: '80px',
          // bottom: '30px',
        },
        // 차트의 엑스축
        xAxis: [
          {
            type: "category",
            data: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            axisPointer: {
              type: "shadow"
            },
            triggerEvent: true
          }
        ],

        yAxis: [
          // 차트의 Y축, 왼쪽
          {
            type: "value",
            name: "kWh/m²",
            axisLine: { show: true },
            axisTick: { show: true },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed"
              }
            }
          },
          // 차트의 Y축, 오른쪽
          {
            type: "value",
            name: "",   // 오른쪽 단위값은 값이 있을때만 노출하기 위해 공백
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed"
              }
            }
          }
        ],
        series: []
      },
    }
  },
  computed: {
    ...mapGetters({
      getEnergyChartData: "main/getEnergyChartData",
    }),
    checkUseYy() {
      return this.useYySelected;  // 현재 선택된 년도값
    },
  },
  watch: {
    checkUseYy(val) {
      this.useYySelected = val  // 데이터 변경된값으로 다시 로드
      this.chartTitle = "에너지원별 에너지 소비량"
      this.goUrl = "main/FETCH_ENERGY_KIND_CHART_DATA"
      this.loadChartData();
    }
  },
  mounted() {
    // 소스 정리 필요
    this.chartTitle = "에너지원별 에너지 소비량"
    this.goUrl = "main/FETCH_ENERGY_KIND_CHART_DATA"
    this.loadChartData();
  },
  methods: {
    // 데이터 로드
    loadChartData() {
      // 차트 초기화
      let i = 0;
      this.option.series.forEach((v) => {
        this.option.series[i].data = ''
        i++;
      });
      this.$store.dispatch(this.goUrl, {
        mgmBldPk: this.mgmBldPk,
        dataCode: this.energyChartType,
        useYyCd: this.useYySelected,
      }).then(() => {
        const chartData = this.$store.state.main.energyChartTotal

        chartData.forEach((v) => {
          const temp = {};

          temp.name = v.cd_nm;
          if (v.cd_nm === "수도") {
            temp.type = 'line';
            temp.yAxisIndex = 1;
            this.option.yAxis[1].name = 'L/m²' //
          } else {
            temp.type = 'bar';
            temp.stack = 'advertising';
          }
          temp.itemStyle = { color: v.cd_color }

          let tempArr = [];
          tempArr = v.use_qty.split(',');
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const a in temp) {
            tempArr[a] = parseInt(tempArr[a], 10); // Explicitly include base as per Álvaro's comment
          }
          temp.data = tempArr;
          this.option.series.push(temp);
        });
        // this.option.series = this.$store.state.main.energyChartTotal
      }).catch(() => {
        console.error('오류')
      })
    }
  }
}
</script>
