<template>
  <div>
    <b-card>
      <h5>{{ this.chartTitle }}</h5>
      <app-echart-stacked-area
        :option-data="option"
      />
    </b-card>
    <!--    <b-button @click="test">
      클릭
    </b-button>-->
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import AppEchartStackedArea from '@core/components/charts/echart/AppEchartStackedArea.vue'
import AppEchartLine from '@core/components/charts/echart/AppEchartLine.vue'
import { mapGetters } from "vuex";

export default {
  components: {
    BCard,
    AppEchartStackedArea,
    // BButton,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    },
    energyChartType: {
      type: String,
      required: true,
    },
    useYySelected: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      goUrl: '',
      chartTitle: '',
      option: {
        xAxisData: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        series: [],
      },
    }
  },
  computed: {
    ...mapGetters({
      getEnergyChartData: "main/getEnergyChartData",
    }),
    checkUseYy() {
      return this.useYySelected;
    },
  },
  watch: {
    checkUseYy(val) {
      this.useYySelected = val

      if (this.energyChartType === "engyKind") {
        this.chartTitle = "에너지원별 에너지 소비량"
        this.goUrl = "main/FETCH_ENERGY_KIND_CHART_DATA"

        this.loadChartData();
      } else {
        this.chartTitle = "용도별 에너지 소비량"
        this.goUrl = "main/FETCH_CLSF_KIND_CHART_DATA"
        this.loadChartData();
      }
    }

  },

  mounted() {
    // 소스 정리 필요
    if (this.energyChartType === "engyKind") {
      this.chartTitle = "에너지원별 에너지 소비량"
      this.goUrl = "main/FETCH_ENERGY_KIND_CHART_DATA"

      this.loadChartData();
    } else {
      this.chartTitle = "용도별 에너지 소비량"
      this.goUrl = "main/FETCH_CLSF_KIND_CHART_DATA"
      this.loadChartData();
    }
  },
  updated() {
  },
  methods: {
    loadChartData() {
      // 차트 초기화
      let i = 0;
      this.option.series.forEach((v) => {
        this.option.series[i].data = ''
        i++;
      });
      this.$store.dispatch(this.goUrl, {
        mgmBldPk: this.mgmBldPk,
        dataCode: this.energyChartType,
        useYyCd: this.useYySelected,
      }).then(() => {
        const chartData = this.$store.state.main.energyChartTotal

        chartData.forEach((v) => {
          const temp = {};

          temp.name = v.cd_nm;
          temp.type = 'line';
          temp.stack = 'Total';
          temp.showSymbol = true;
          temp.color = v.cd_color;
          temp.areaStyle = {
            color: v.cd_color
          };
          temp.lineStyle = {
            width: 1
          };
          let tempArr = [];
          tempArr = v.use_qty.split(',');
          // eslint-disable-next-line guard-for-in,no-restricted-syntax
          for (const a in temp) {
            tempArr[a] = parseInt(tempArr[a], 10); // Explicitly include base as per Álvaro's comment
          }
          temp.data = tempArr;
          this.option.series.push(temp);
        });
        // this.option.series = this.$store.state.main.energyChartTotal
      }).catch(() => {
        console.error('오류')
      })
    }
  }
}
</script>
