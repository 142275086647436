<!-- eslint-disable -->
<template>
  <div id="wrap" style="width: 100%; height: 100%;">
    <div class="container">
      <div class="gis">
        <ul class="map-layer"></ul>
        <div class="energy-ctx-menu btn_3dview">
          <ul>
            <li data-cb="bld3d" value="">3D건물</li>
            <li data-cb="bldPoint" value="">kboss설문</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn_energyView">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldEnergyDetailPopup">현재 건물 에너지소비량</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn_bldView">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldPoint_custom">kboss설문</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn-add1">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldPoint" value="">kboss설문</li>
          </ul>
        </div>
        <div class="energy-ctx-menu btn-add2">
          <ul>
            <li data-cb="bldDetailPopup">현재 건물 기본정보</li>
            <li data-cb="bldEnergyDetailPopup">현재 건물 에너지소비량</li>
            <li data-cb="bldPoint" value="">kboss설문</li>
          </ul>
        </div>
        <div id="map" class="gis-map" style="top: 50px;">
          <div class="map-level"></div>
          <div class="btn-area">
            <div class="group" style="margin-bottom: 2px;border-radius: 0.28571429rem;border: 0px;">
              <button type="button" id="selectArea" class="btn-tooltip">
               층별
              </button>
              <button type="button" id="btn-survey" class="btn-tooltip" style="display:none">
               설문
              </button>
            </div>
          </div>
        </div>
        <div class="btn-floor">
          
        </div>
        <button id="btn-floor-close">X</button>
        <div id="select-container">
          <div class="select-box" aria-haspopup="true">
            <button id="btn-dropdown" class="btn-dropdown">만족도 조사</button>
            <nav id="nav-dropdown" class="nav-dropdown">
              <ul>
                <li data-value="1-1.">1-1. 업무공간에서 개별업무 및 사품보관 등에 사용되는 공간의 양에 대해서 얼마나 만족하십니까?</li>
                <li data-value="1-2.">1-2. 업무공간의 시각적인 프라이버시에 대해서 얼마나 만족하십니까?</li>
                <li data-value="1-3.">1-3. 업무공간에서 직장동료들과의 상호소통의 용이한 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="2-1.">2-1. 업무공간의 전반적인 온도에 얼마나 만족하십니까?</li>
                <li data-value="2-2.">2-2. 업무공간의 전반적인 습도에 얼마나 만족하십니까?</li>
                <li data-value="3-1.">3-1. 업무공간의 실내공기 신선도가 쾌적한 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="3-2.">3-2. 업무공간의 실내공기 냄새가 쾌적한 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="4-1.">4-1. 업무공간의 인공조명의 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="4-2.">4-2. 업무공간의 자연채광의 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="4-3.">4-3. 업무공간의 조명의 시각적인 편안감(눈부심, 반사, 대조도 등)에 대해서 얼마나 만족하십니까?</li>
                <li data-value="5-1.">5-1. 업무공간의 소음도(dB) 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="5-2.">5-2. 업무공간 내 대화의 프라이버시 보장 정도에 대해서 얼마나 만족하십니까?</li>
                <li data-value="6-1." class="on">6-1. 모든 것을 고려하여 개인 업무공간에 얼마나 만족하십니까?</li>
                <li data-value="6-2.">6-2. 이 건물의 환경 조건(예 : 열, 공기질, 조명, 소음, 사무실 공간 배치)에 따라 업무능력이 어느정도 향상 또는 저하되었는지 추정해주십시오.</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>

      <div id="loading">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <div
          id="modal_sel"
          class="popLayer draggable"
          style="width:270px; height: fit-content;"
      >
        <dl class="popCntType">
          <dt>
            선택영역 건물정보
          </dt>
          <dd
              class="popCnts"
              style="padding : 20px 20px 20px 20px !important;"
          ></dd>
        </dl>
        <a
            href="#"
            class="btnClosePop"
            onclick="gis.common.hideModal({popID : 'modal_sel', find : '.popCnts'});return false;"
        ><img
            src="/gis/map/common/images/btn_close_pop.png"
            alt="팝업 닫기"
        /></a>
      </div>

      <div
          id="modal_all"
          class="popLayer draggable"
          style="width:270px; height: fit-content;"
      >
        <dl class="popCntType">
          <dt>
            전체영역 장비개수
          </dt>
          <dd
              class="popCnts"
              style="padding : 20px 20px 20px 20px !important;"
          ></dd>
        </dl>
        <a
            href="#"
            class="btnClosePop"
            onclick="gis.common.hideModal({popID : 'modal_all', find : '.popCnts', imgID : '#sel_all', imgFile : './common/images/gis/icon_all.png'});return false;"
        ><img
            src="/gis/map/common/images/btn_close_pop.png"
            alt="팝업 닫기"
        /></a>
      </div>
    </div>
    <!--  건물 정보 : S -->
    <div>
      <b-modal
          v-model="modalBuildInfo"
          size="lg"
          centered
          title="▶ 건물 기본 정보"
          ok-title="확인"
          ok-only
      >
        <b-tabs
            content-class="mt-2"
            class="modal-style"
            justified
        >
          <b-tab
              title="건축물 대장"
              active
          >
            <build-basic-info-component :mgm-bld-pk="mgmBldPk"/>
          </b-tab>
          <b-tab title="층별 개요">
            <build-floor-overview-component :mgm-bld-pk="mgmBldPk"/>
          </b-tab>
          <b-tab title="인허가 리스트">
            <build-license-list-component :mgm-bld-pk="mgmBldPk"/>
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
    <!--  건물 정보 : E -->
    <div>
      <b-modal
          v-model="modalMapAreaInfo"
          size="lg"
          centered
          :title="areaTitle"
          ok-title="확인"
          ok-only
      >
        <map-gis-area-table-component
            :map-type="mapType"
            :sigungu-id="sigunguId"
            :dong-id="dongId"
        />
      </b-modal>
    </div>
    <div>
      <b-modal
          v-model="modalMapAreaInfo"
          size="lg"
          centered
          :title="areaTitle"
          ok-title="확인"
          ok-only
      >
        <map-gis-area-table-component
            :map-type="mapType"
            :sigungu-id="sigunguId"
            :dong-id="dongId"
        />
      </b-modal>
    </div>
    <!--    에너지 사용랑-->
    <div>
      <b-modal
          v-model="modalEnergyInfo"
          size="lg"
          centered
          title="▶ 건물별 에너지소비량"
          ok-title="확인"
          ok-only
      >
        <b-tabs
            content-class="mt-2"
            class="modal-style"
            justified
        >
          <b-tab
              title="에너지 소비량"
              active
          >
            <energy-use-info-component
                :mgm-bld-pk="mgmBldPk"
            />
          </b-tab>
          <b-tab
              title="계량기 정보"
          >
            <energy-meter-info-component
                :mgm-bld-pk="mgmBldPk"
            />
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
    <!-- 슬라이드 저장  -->
    <div v-if="this.buildClickTempInfo">
      <map-gis-temp-build-info-component
          :mgm-bld-pk="mgmBldPk"
      />
    </div>
    <!-- 올가미 -->
    <div>
      <b-modal
          v-model="modalLassoInfo"
          size="lg"
          centered
          title="▶ 올가미 선택 팝업"
          ok-title="확인"
          ok-only
      >
        <build-lasso-info-component
            :mgm-bld-pk-lasso="mgmBldPkLasso"
        />
      </b-modal>
    </div>

  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl';
window.mapboxgl = mapboxgl;
import bim from "../../../public/gis/map/gis/js/all4/3dModel";
import layer from "../../../public/gis/map/gis/js/all4/layerControls";
import * as threeLayer from "../../../public/gis/map/gis/js/all4/threeLayer";
import {
	PreventDragClick
} from "../../../public/gis/map/gis/js/common/PreventDragClick";
import code from "../../../public/gis/map/gis/js/common/code";
import * as THREE from 'three';

import {
	BModal,
	BTab,
	BTabs,
	VBModal,
	VBToggle,
	BButton
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import BuildFloorOverviewComponent from "@/views/building/buildBaiscInfo/BuildFloorOverviewComponent";
import BuildBasicInfoComponent from "@/views/building/buildBaiscInfo/BuildBasicInfoComponent";
import BuildLicenseListComponent from "@/views/building/buildBaiscInfo/BuildLicenseListComponent";
import MapGisAreaTableComponent from "@/views/building/MapGisAreaTableComponent";

import EnergyUseInfoComponent from "@/views/building/EnergyUse/EnergyUseInfoComponent";
import EnergyMeterInfoComponent from "@/views/building/EnergyUse/EnergyMeterInfoComponent";
import MapGisTempBuildInfoComponent from "@/views/building/MapGisTempBuildInfoComponent";
import BuildLassoInfoComponent from "@/views/building/buildBaiscInfo/BuildLassoInfoComponent";
import {
	mapGetters
} from "vuex";

import * as turf from '@turf/turf'

export default {
	components: {
		BuildLassoInfoComponent,
		MapGisTempBuildInfoComponent,
		MapGisAreaTableComponent,

		BModal,

		BuildLicenseListComponent,
		BuildFloorOverviewComponent,
		BuildBasicInfoComponent,
		BTabs,
		BTab,
		BButton,

		EnergyMeterInfoComponent,
		EnergyUseInfoComponent,
	},
	directives: {
		'b-modal': VBModal,
		'b-toggle': VBToggle,
		Ripple,
	},
	data() {
		return {
			// 전역변수 클릭 여부
			buildClickTempInfo: false,

			modalBuildInfo: false,
			modalMapAreaInfo: false,
			modalEnergyInfo: false,
			modalLassoInfo: false,

			mgmBldPk: null, // 건물 PK
			mgmBldPkLasso: null, // 올가미로 묶은 건물 PK 배열
			areaTitle: null, // modal의 타이틀값
			mapType: null, // 시군구 or 읍면동 구분값(sigungu, dong)
			sigunguId: null, // 시군구 PK
			dongId: null, // 동 PK
			// 건물 정보 임시 저장 배열
			mgmBldPkArr: [],

			commonEnergy: {},
			purpsArr: [],
			kictModelName: "",
			statusData: {
				engyCd: "2017",
				year: "11",
				clsfCd: "eb"
			},
			preData: "tb_engy_2017_11_eb",
		}
	},
	computed: {
		...mapGetters({
			mainSelected: "main/getSelectedCodeData",
		}),
	},
	mounted() {
		mapboxgl.accessToken =
			"pk.eyJ1IjoibGFyZ2VvbmUiLCJhIjoiY2tyanlmZTZ3MTl1czJucGs4NmYzMndwOSJ9.59kffzfEkzqJLHWT5WgrUg";
		let mapOriginCenter = [126.73858609597376, 37.669643134581285];
		let mapOriginZoom = 17.5;
		let layerMgm;
		let selectedFeature;
		let selectedFeatureProperties;
		let prevFloorCnt = 0;
		let preventDragClick;

		if (!!location.search) {
			let result = get_query();
			if (!!result.lng && !!result.lat) {
				if (document.referrer.includes("http://checkup.building-energy.org:9090/")) {
					result.zoom = parseInt(result.zoom) + 1;
				}
				if (!result.zoom) {
					result.zoom = 17.5;
				}
				mapOriginCenter = [result.lng, result.lat];
				mapOriginZoom = result.zoom;
			}
		}

		map = new mapboxgl.Map({
			container: "map",
			style: "mapbox://styles/mapbox/light-v10?optimize=true",
			center: mapOriginCenter,
			zoom: mapOriginZoom,
			bearing: 0,
			pitch: 60,
			attributionControl: false,
			doubleClickZoom: false,
			antialias: false,
			maxTileCacheSize: 2048,
			maxZoom: 22.3,
			minZoom: 6,
			maxBounds: [
				118.47210977735858, 30.909944590789138, 136.19768873771102,
				41.57339747769777,
			],
			boxZoom: false,
			optimizeForTerrain: false,
			testMode: true,
		});
		map.addControl(new mapboxgl.NavigationControl());
		map.addControl(new mapboxgl.ScaleControl(), 'bottom-right');

		function get_query() {
			var url = document.location.href;
			var qs = url.substring(url.indexOf("?") + 1).split("&");
			for (var i = 0, result = {}; i < qs.length; i++) {
				qs[i] = qs[i].split("=");
				result[qs[i][0]] = decodeURIComponent(qs[i][1]);
			}
			return result;
		}

		map.on("load", function () {
			$(".map-level").text(Math.floor(map.getZoom()));
			layer.addRaster();
			map.addLayer(bim["3d-model"]);
			layer.init();

			map.setLayoutProperty("3d-model", "visibility", "visible");
			preventDragClick = new PreventDragClick(map.getCanvas());

		});

		map.on("moveend", function (e) {
			let renewURL = location.href;

			renewURL = renewURL.replace(/\?+.+/gi, "");
			renewURL += "?lng=" + map.getCenter().lng;
			renewURL += "&lat=" + map.getCenter().lat;
			renewURL += "&zoom=" + map.getZoom();

			history.pushState(null, null, renewURL);
		});
		map.on('zoom', (e) => {
			$(".map-level").text(Math.floor(map.getZoom()));
		});


		map.on("contextmenu", function (e) {
			$(".btn_3dview").hide();
			$(".btn_energyView").hide();
			$(".btn_bldView").hide();
			$(".btn-add1").hide();
			$(".btn-add2").hide();

			if (preventDragClick.mouseMoved) return;
			bim["3d-model"].raycast(e.point, true);
			let firstBld = map.queryRenderedFeatures(e.point).filter((e) => {
				return e.source == "tb_engy_bld_yy_re";
			});
			
			if (Array.isArray(firstBld) && firstBld.length !== 0) {
				layerMgm = firstBld[0].properties['mgm_bld_pk'];
				selectedFeature = firstBld[0];
				console.log(selectedFeature)
				selectedFeatureProperties = selectedFeature.properties;
				if (map.queryRenderedFeatures(e.point)[0].properties["bd_mgt_sn"] === '1168010600110240003000001') {
					$(".btn-add2").show();
					$(".btn-add2").css({
						left: e.point.x + 10,
						top: e.point.y
					});
				} else {
					$(".btn_energyView").show();
					$(".btn_energyView").css({
						left: e.point.x + 10,
						top: e.point.y
					});
				}
			} else {
				let secondBld = map.queryRenderedFeatures(e.point).filter((e) => {
					return e.source == "tl_spbd_buld";
				});

				if (Array.isArray(secondBld) && secondBld.length !== 0) {
					console.log(secondBld)
					layerMgm = secondBld[0].properties['mgm_bld_pk'];
					selectedFeature = secondBld[0];
					selectedFeatureProperties = selectedFeature.properties;
					if (map.queryRenderedFeatures(e.point)[0].properties["bd_mgt_sn"] === '1144010500100700002003775') {
						$(".btn-add1").show();
						$(".btn-add1").css({
							left: e.point.x + 10,
							top: e.point.y
						});
					} else {
						$(".btn_bldView").show();
						$(".btn_bldView").css({
							left: e.point.x + 10,
							top: e.point.y
						});
					}
				}
			}
			console.log(layerMgm, selectedFeature)

			window.event.returnValue = false;
		});
		map.on("mousedown", function (e) {
			$(".btn_3dview").hide();
			$(".btn_energyView").hide();
			$(".btn_bldView").hide();
			$(".btn-add1").hide();
			$(".btn-add2").hide();
		});

		$(".btn_energyView li").on("click", (e) => {
			$(".btn_energyView").hide();
			if (e.target.dataset.cb === 'bldDetailPopup') {
				this.onClickBuildInfo(layerMgm);
			} else {
				this.onClickEnergyUse(layerMgm);
			}
		})
		$(".btn_bldView li").on("click", (e) => {
			$(".btn_bldView").hide();

			if (e.target.dataset.cb === 'bldDetailPopup') { //현재 건물 기본정보
				this.onClickBuildInfo(layerMgm);

			} else if (e.target.dataset.cb === 'bldPoint_custom') { //kboss설문

				// glb 모델 최초상태로
				clearModel();

				let selectedObject = scene.getObjectByName("survey");
				selectedObject&&selectedObject.parent.remove(selectedObject);
				bldPoint_customCloseHandler();
				
				showSurveyElement_custom();

			}

		})

		function bldPoint_customCloseHandler(){
			if (prevFloorCnt > 0) {
					for (let i = 1; i <= prevFloorCnt; i++) {
						map.removeLayer("custom_" + i);
						map.removeSource("custom_" + i);
					}
					prevFloorCnt = 0;
				}
		}

		$(".btn-add1 li").on("click", (e) => {
			$(".btn-add1").hide();
			if (e.target.dataset.cb === 'bldDetailPopup') {
				this.onClickBuildInfo(layerMgm);
			} else {
				selectedModel = 'kict98'
				showSurveyElement();
				map.setFilter("tl_spbd_buld", [
					"!in",
					"bd_mgt_sn",
					"1144010500100700002003775",
				]);
			}
		})

		$(".btn-add2 li").on("click", (e) => {
			$(".btn-add2").hide();
			if (e.target.dataset.cb === 'bldDetailPopup') {
				this.onClickBuildInfo(layerMgm);
			} else if (e.target.dataset.cb === 'bldEnergyDetailPopup') {
				this.onClickEnergyUse(layerMgm);
			} else {
				selectedModel = 'kict99'
				showSurveyElement();
				map.setFilter("tl_spbd_buld", [
					"!in",
					"bd_mgt_sn",
					"1168010600110240003000001"
				]);
				map.setFilter("tb_engy_bld_yy_re", [
					"!in",
					"mgm_bld_pk",
					"11680-100222189"
				]);
			}
		})


		function showSurveyElement_custom() {
			clearInterval(sensorFlicker);

			let floorLevel = Number(selectedFeatureProperties.gro_flo_co) || 0;
			prevFloorCnt = floorLevel;

			let defaultInvisibleBuilding = ["4128710400123110001004201",
					"4128710400123110001012494",
					"4128710400123110001012497",
					"4128710400123110001012222",
					"4128710400123110001012498",
					"4128710400123110001012223",
					"4128710400123110001012221",
					"4128710400123110001012492",
					"4128710400123110001012499",
					"4128710400123110001012491",
					"4128710400123110001012493",
					"4128710400123110000012292",
					"4128710400123110000012298",
					"4128710400123110000012293",
					"4128710400123110000012295",
					"4128710400123110000004200",
					"4128710400123110000012297",
					"4128710400123110000012504",
					"4128710400123110000012294"];

			let _filter = [
				"!in",
				"bd_mgt_sn",
				selectedFeatureProperties.bd_mgt_sn
			];

			map.setFilter("tl_spbd_buld", _filter.concat(defaultInvisibleBuilding));

			const features = map.querySourceFeatures(
				"tl_spbd_buld", {
					sourceLayer: "tl_spbd_buld",
					filter: ["in", "bd_mgt_sn", selectedFeatureProperties.bd_mgt_sn]
				}
			);

			const key = "custom";
			
			for (let i = 1; i <= floorLevel; i++) {

				map.addSource(`${key}_${i}`, {
					type: 'geojson',
					data: {
						"type": "FeatureCollection",
						"features": features
					}
				});

				map.addLayer({
					id: `${key}_${i}`,
					type: "fill-extrusion",
					source: `${key}_${i}`,
					minzoom: 6,
					layout: {
						visibility: "none"
					},
					paint: {
						"fill-extrusion-color": "#cdcdcd",
						"fill-extrusion-height": i * 3,
						"fill-extrusion-base": i * 3 - 3,
						"fill-extrusion-opacity": 1,
					},
				});
			}

			showSatisfaction_custom("6-1.", floorLevel);

			while (btnFloor.hasChildNodes()) {
				btnFloor.removeChild(btnFloor.firstChild);
			}
			for (let i = 1; i <= floorLevel; i++) {
				map.setLayoutProperty(`custom_${i}`, "visibility", "visible");
				map.setPaintProperty(`custom_${i}`, "fill-extrusion-opacity", 1);

				let button = document.createElement('button');
				let text = document.createTextNode(`${i}층`);
				button.setAttribute('data-value', `F0${i}`);
				button.appendChild(text);
				button.addEventListener('click', btnFloorListener_custom);
				
				if (i === floorLevel) {
					button.classList.add('on');
					sensorFlicker = setInterval(function () {
						let opacity_ = map.getPaintProperty(`custom_${i}`, "fill-extrusion-opacity");
						if (opacity_ == 0.6) {
							map.setPaintProperty(`custom_${i}`, "fill-extrusion-opacity", 0.2);
						} else {
							map.setPaintProperty(`custom_${i}`, "fill-extrusion-opacity", 0.6);
						}
					}, 400);
				}
				btnFloor.prepend(button);
			}

			$(".btn-floor").show();
			$("#btn-floor-close").show();
			$("#select-container").show();

		}

		function showSurveyElement() {
			clearInterval(sensorFlicker);
			let floorLevel = code.kictFloor[selectedModel];
			showSatisfaction("6-1.", floorLevel.toString());
			while (btnFloor.hasChildNodes()) {
				btnFloor.removeChild(btnFloor.firstChild);
			}
			for (let i = 1; i <= floorLevel; i++) {
				map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "visible");
				map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 1);

				let button = document.createElement('button');
				let text = document.createTextNode(`${i}층`);
				button.setAttribute('data-value', `F0${i}`);
				button.appendChild(text);
				button.addEventListener('click', btnFloorListener);
				if (i === floorLevel) {
					button.classList.add('on');
					sensorFlicker = setInterval(function () {
						let opacity_ = map.getPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity");
						if (opacity_ == 0.6) {
							map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 0.2);
						} else {
							map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 0.6);
						}
					}, 400);
				}
				btnFloor.prepend(button);
			}

			$(".btn-floor").show();
			$("#btn-floor-close").show();
			$("#select-container").show();

		}

		const btnFloor = document.querySelector('.btn-floor');

		$(".btn_3dview li").on("click", function (e) {
			$(".btn_3dview").hide();
			let modelName = $(this).attr("value");
			console.log(modelName);

			if (e.target.dataset.cb === 'bld3d') {
				if (modelName) {
					var options =
						"top=10, left=10, width=900, height=700, status=no, menubar=no, toolbar=no, resizable=no, location=no, scrollbars=no";
					window.open(
						"http://checkup.building-energy.org:12230/popup/?modelName=" + modelName,
						"popup",
						options
					);
				} else {
					console.log("null");
				}
			} else if (e.target.dataset.cb === 'bldPoint') {
				
				clearModel();
				bldPoint_customCloseHandler();

				scene.children[1].children.forEach(function (glb) {
				if (modelName === "100240") {
					if (glb.name === "100260") {
						glb.visible = false;
					} else {
						glb.visible = true;
					}
				} else {
					if (glb.name === modelName) {
						glb.visible = false;
					} else {
						glb.visible = true;
					}
				}
			})

				selectedModel = "kict" + modelName.slice(3, 5);
				showSurveyElement();
			}
		});

		function clearModel(){
			if (selectedModel !== undefined && selectedModel.length > 0) {
				let preLevel = code.kictFloor[selectedModel];
				for (let i = 1; i <= preLevel; i++) {
					map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "none");
				}
				let selectedObject = scene.getObjectByName("survey");
				selectedObject&&selectedObject.parent.remove(selectedObject);
			}

			selectedModel&&scene.children[1].children.forEach(function (glb) {
				let glbName = "100" + selectedModel.slice(4, 6) + "0";
				if (glbName === "100240") glbName = "100260";
				if (glb.name === glbName) {
					glb.visible = true;
				}
			})

			selectedModel = '';

			map.setFilter("tl_spbd_buld", [
				"!in",
				"bd_mgt_sn",
				"4128710400123110001004201",
				"4128710400123110001012494",
				"4128710400123110001012497",
				"4128710400123110001012222",
				"4128710400123110001012498",
				"4128710400123110001012223",
				"4128710400123110001012221",
				"4128710400123110001012492",
				"4128710400123110001012499",
				"4128710400123110001012491",
				"4128710400123110001012493",
				"4128710400123110000012292",
				"4128710400123110000012298",
				"4128710400123110000012293",
				"4128710400123110000012295",
				"4128710400123110000004200",
				"4128710400123110000012297",
				"4128710400123110000012504",
				"4128710400123110000012294",
			]);

		}

		const btnSelect = document.getElementById("btn-dropdown");
		const navSelect = document.getElementById("nav-dropdown");

		btnSelect.addEventListener('click', () => {
			if (!btnSelect.classList.contains("is-open")) {
				btnSelect.classList.add("is-open");
				navSelect.classList.add("is-open");
			} else {
				btnSelect.classList.remove("is-open");
				navSelect.classList.remove("is-open");
			}
		});

		const navList = navSelect.querySelector("ul");
		navList.addEventListener('click', (e) => {
			navList.querySelectorAll('li').forEach(e => {
				e.classList.remove("on");
			})
			e.target.classList.add("on");
			const selectedfloor = document.querySelector('.btn-floor button.on');
			let selectedObject = scene.getObjectByName("survey");
			selectedObject&&selectedObject.parent.remove(selectedObject);

			let strIdx = selectedfloor.dataset.value;
            let idx = strIdx.replace("F", "");
            let index = Number(idx) + ""; // legacy 기준에 맞게..
			
			showSatisfaction(e.target.dataset.value, index);
			
			btnSelect.classList.remove("is-open");
			navSelect.classList.remove("is-open");
		});


		// 층별 버튼 커스터마이징
		let btnFloorListener_custom = (e) => {
			clearInterval(sensorFlicker);
			btnFloor.querySelectorAll('button').forEach(e => {
				e.classList.remove("on");
			})
			e.target.classList.add("on");

			navList.querySelectorAll('li').forEach(e => {
				e.classList.remove("on");
			})
			navList.querySelector('li:nth-child(13)').classList.add("on");
			let selectedObject = scene.getObjectByName("survey");
			selectedObject&&selectedObject.parent.remove(selectedObject);

			let strIdx = e.target.dataset.value;
			let idx = strIdx.replace("F", "");

			let index = Number(idx);
			let floorLevel = selectedFeatureProperties.gro_flo_co || 0;

			showSatisfaction_custom("6-1.", index);
			for (let i = 1; i <= floorLevel; i++) {
				map.setLayoutProperty(`custom_${i}`, "visibility", "visible");
				map.setPaintProperty(`custom_${i}`, "fill-extrusion-opacity", 1);
			}
			for (let i = floorLevel; i > index; i--) {
				map.setLayoutProperty(`custom_${i}`, "visibility", "none");
			}

			sensorFlicker = setInterval(function () {
				var opacity_ = map.getPaintProperty(`custom_${index}`, "fill-extrusion-opacity");
				if (opacity_ == 0.6) {
					map.setPaintProperty(`custom_${index}`, "fill-extrusion-opacity", 0.2);
				} else {
					map.setPaintProperty(`custom_${index}`, "fill-extrusion-opacity", 0.6);
				}
			}, 400);
		};

		let btnFloorListener = (e) => {
			clearInterval(sensorFlicker);
			btnFloor.querySelectorAll('button').forEach(e => {
				e.classList.remove("on");
			})
			e.target.classList.add("on");

			navList.querySelectorAll('li').forEach(e => {
				e.classList.remove("on");
			})
			navList.querySelector('li:nth-child(13)').classList.add("on");
			let selectedObject = scene.getObjectByName("survey");
			selectedObject.parent.remove(selectedObject);

			let index = e.target.dataset.value.slice(2, 3);
			let floorLevel = code.kictFloor[selectedModel];
			showSatisfaction("6-1.", index);
			for (let i = 1; i <= floorLevel; i++) {
				map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "visible");
				map.setPaintProperty(`${selectedModel}_${i}`, "fill-extrusion-opacity", 1);
			}
			for (let i = floorLevel; i > index; i--) {
				map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "none");
			}

			sensorFlicker = setInterval(function () {
				var opacity_ = map.getPaintProperty(`${selectedModel}_${index}`, "fill-extrusion-opacity");
				if (opacity_ == 0.6) {
					map.setPaintProperty(`${selectedModel}_${index}`, "fill-extrusion-opacity", 0.2);
				} else {
					map.setPaintProperty(`${selectedModel}_${index}`, "fill-extrusion-opacity", 0.6);
				}
			}, 400);
		}
		const btnFloorClose = document.querySelector('#btn-floor-close');
		btnFloorClose.addEventListener('click', (e) => {


			$(".btn-floor").hide();
			$("#btn-floor-close").hide();
			$("#select-container").hide();
			clearInterval(sensorFlicker);

			let preLevel = code.kictFloor[selectedModel];
			for (let i = 1; i <= preLevel; i++) {
				map.setLayoutProperty(`${selectedModel}_${i}`, "visibility", "none");
			}
			let selectedObject = scene.getObjectByName("survey");
			selectedObject&&selectedObject.parent.remove(selectedObject);
			
			selectedModel&&scene.children[1].children.forEach(function (glb) {
					let glbName = "100" + selectedModel.slice(4, 6) + "0";
					if (glbName === "100240") glbName = "100260";
					if (glb.name === glbName) {
						glb.visible = true;
					}
				})
			selectedModel = '';

			bldPoint_customCloseHandler();

			map.setFilter("tl_spbd_buld", [
				"!in",
				"bd_mgt_sn",
				"4128710400123110001004201",
				"4128710400123110001012494",
				"4128710400123110001012497",
				"4128710400123110001012222",
				"4128710400123110001012498",
				"4128710400123110001012223",
				"4128710400123110001012221",
				"4128710400123110001012492",
				"4128710400123110001012499",
				"4128710400123110001012491",
				"4128710400123110001012493",
				"4128710400123110000012292",
				"4128710400123110000012298",
				"4128710400123110000012293",
				"4128710400123110000012295",
				"4128710400123110000004200",
				"4128710400123110000012297",
				"4128710400123110000012504",
				"4128710400123110000012294",
			]);
			map.setFilter("tb_engy_bld_yy_re", ["==", "use_yy", "2017"]);
		})
		// contextMenu = new gis.menu.ContextMenu({
		//     MENU_ID: "MAP_POP",
		// }).load(); // 지도 콘텍스트 메뉴 생성

		// const gisLayerTree = new gis.menu.LayerTree(); // 레이어 트리 생성
		// contextMenu = new gis.menu.ContextMenu({
		//   MENU_ID: "MAP_POP",
		// }).load(); // 지도 콘텍스트 메뉴 생성

		// gis.map.event.init(); // 지도 이벤트 초기화

		// gis.search.init(map); // 검색기능 임시 레이어
		// gis.map.layer.addTempLayers(map); // 임시레이어 추가

		// // 현재위치초기화
		// console.debug('global inputMapInitData ');
		// console.debug(inputMapInitData);
		// if (inputMapInitData != null && inputMapInitData.allForLand != null) {
		//   // 올포랜드(3D맵에서 제공해주는 데이터가 있다면 해당 위치로 이동)
		//   gis.map.setCenter(map, {
		//     centerCoord: [inputMapInitData.allForLand.centorLon, inputMapInitData.allForLand.centorLat],
		//     oldCrsCode: "EPSG:4326",
		//     newCrsCode: "EPSG:5179",
		//     zoomLvl: parseInt(inputMapInitData.allForLand.zoom) - 4
		//   });
		//   inputMapInitData = null;
		//   // inputMapInitData = { allForLand : { centorLon :  127.50507097730406
		//   //                          ,centorLat : 37.48586136550372
		//   //                          ,zoom      : 16.857093108851235
		//   //                         }
		//   //          };
		// } else { // 없다면 쿠키에서가져오기(default)
		//   const mapInit = gis.common.cookie.getJson("mapInit");
		//   if (mapInit != null) {
		//     map.getView().setZoom(mapInit.zoomLvl);
		//     map.getView().setCenter(mapInit.center);
		//   }
		// }
		// Fn.Map.debug();

		// // 	gis.common.getLyrOptnInfo({mode : "js"});  //레이어 옵션 설정가져오기
		const btnSurvey = document.querySelector("#btn-survey");
		btnSurvey.addEventListener('click', (e) => {
			if (!e.target.classList.contains('active')) {
				e.target.classList.add('active');
			} else {
				e.target.classList.remove('active');
			}
			let status = e.target.classList.contains("active");
			if (status) {
				scene.visible = false;
				showSatisfaction();
			} else {
				scene.visible = true;
				Object.keys(code.kictFloor).forEach((key) => {
					for (let i = 1; i <= code.kictFloor[key]; i++) {
						map.setLayoutProperty(`${key}_${i}`, "visibility", "none");
					}
				});
			}
		});


		function showSatisfaction_custom(statisFactionNumber = "6-1.", floor) {

			floor = Number(floor);

			const features = map.querySourceFeatures(
				"tl_spbd_buld", {
					sourceLayer: "tl_spbd_buld",
					filter: ["in", "bd_mgt_sn", selectedFeatureProperties.bd_mgt_sn]
				}
			);
			
			const randomPointInPoly = function(polygonGeoJson) {
				var bounds = getPolygonBoundingBox(polygonGeoJson); 
				
				//[xMin, yMin][xMax, yMax]
				var x_min  = bounds[0][0];
				var x_max  = bounds[1][0];
				var y_min  = bounds[0][1];
				var y_max  = bounds[1][1];

				var lat = y_min + (Math.random() * (y_max - y_min));
				var lng = x_min + (Math.random() * (x_max - x_min));
					
				var pt = turf.point([lng, lat]);
				var inside = turf.booleanPointInPolygon(pt, polygonGeoJson , {ignoreBoundary:true});

				if (inside) {
					return pt
				} else {
					return randomPointInPoly(polygonGeoJson)
				}
			}

			function getPolygonBoundingBox(feature) {
				var bounds = [[], []];
				var polygon;
				var latitude;
				var longitude;

				for (var i = 0; i < feature.geometry.coordinates.length; i++) {
					if (feature.geometry.coordinates.length === 1) {
						// Polygon coordinates[0][nodes]
						polygon = feature.geometry.coordinates[0];
					} else {
						// Polygon coordinates[poly][0][nodes]
						polygon = feature.geometry.coordinates[i][0];
					}

					for (var j = 0; j < polygon.length; j++) {
						longitude = polygon[j][0];
						latitude = polygon[j][1];

						bounds[0][0] = bounds[0][0] < longitude ? bounds[0][0] : longitude;
						bounds[1][0] = bounds[1][0] > longitude ? bounds[1][0] : longitude;
						bounds[0][1] = bounds[0][1] < latitude ? bounds[0][1] : latitude;
						bounds[1][1] = bounds[1][1] > latitude ? bounds[1][1] : latitude;
					}
				}

				return bounds;
			}
			let filterData = [];

			for(const feature of features){
				for(let i = 0 ; i < 10 ; i++){
					filterData.push(randomPointInPoly(feature));
				}
			}

			let group = new THREE.Group();

			filterData.forEach(function (v) {
				let statis = Math.round(Math.random() * 6) + 1 // 1~7
				let color = code.satisfactionColor[statis.toString()];
				let lon = v.geometry.coordinates[0];
				let lat = v.geometry.coordinates[1];
				group.add(threeLayer.addSpacePeople([lon, lat], floor, color));
			});
			group.position.y = 3 * floor - 1.5;
			group.name = "survey";
			scene.add(group);
		}

		function showSatisfaction(statisFactionNumber = "6-1.", floor) {

			if(selectedModel == undefined || selectedModel == ""){
				showSatisfaction_custom(statisFactionNumber , floor);
				console.log("data is gala");
				return "junk";
			}
			
			$.ajax({
				url: `data/kictsurvey/${selectedModel}.json`,
				async: true,
				type: "GET",
				success: function (data) {

					let filterData = data.filter(e => e.floor === floor);	
					let group = new THREE.Group();

					filterData.forEach(function (v) {
						let statis = parseInt(v[statisFactionNumber]);
						let color = code.satisfactionColor[statis.toString()];
						let lon = +v["만족도 조사 위치 경도"];
						let lat = +v["만족도 조사 위치 위도"];
						group.add(threeLayer.addSpacePeople([lon, lat], v.floor, color));
					});
					group.position.y = 3 * floor - 1.5;
					group.name = "survey";
					scene.add(group);
				},
				error: function () {
					console.log("error");
				},
			});
			
		}





		// function showSatisfaction(statisFactionNumber = "6-1.") {
		//   Object.keys(code.kictFloor).forEach((key) => {
		//     $.ajax({
		//       url: "data/kictsurvey/" + key + ".json",
		//       async: true,
		//       type: "GET",
		//       success: function (data) {
		//         let colorOption = {'1': ["case"], '2': ["case"], '3': ["case"], '4': ["case"], '5': ["case"], '6': ["case"], '7': ["case"], '8': ["case"]};
		//         let arrSpaceCheck = {'1': {}, '2': {}, '3': {}, '4': {}, '5': {}, '6': {}, '7': {}, '8': {}};
		//         data.forEach(function (v) {
		//           let statis = parseInt(v[statisFactionNumber]);
		//           if(arrSpaceCheck[v.floor][v.CellSpace] !== undefined) {
		//             statis = Math.round((arrSpaceCheck[v.floor][v.CellSpace] + statis) / 2);
		//           }
		//           arrSpaceCheck[v.floor][v.CellSpace] = statis;

		//           let cellSapceOption = ["==", ["get", "gmlid"], v.CellSpace];
		//           let cellSpaceColor = code.satisfactionColor[statis.toString()];
		//           colorOption[v.floor].push(cellSapceOption, cellSpaceColor);
		//         });

		//         for(let i = 1; i <= code.kictFloor[key]; i++ ) {
		//           colorOption[i].push("#cdcdcd");
		//           console.log(`${key}_${i}`);
		//           map.setFilter(`${key}_${i}`, undefined);
		//           if(colorOption[i].length >= 3) map.setPaintProperty(`${key}_${i}`, "fill-extrusion-color", colorOption[i]);
		//           map.setLayoutProperty(`${key}_${i}`, "visibility", "visible");
		//         }
		//       },
		//       error: function () {
		//         console.log("error");
		//       },
		//     });
		//   });
		// }

	},
	methods: {
		onClickBuildInfo(mgmBldPk) {
			// 전역변수 true
			this.buildClickTempInfo = true
			//this.mgmBldPkArr.push(mgmBldPk)

			this.modalBuildInfo = !this.modalBuildInfo
			this.mgmBldPk = mgmBldPk
		},
		onClickMapArea(title, mapType, sigunguId, dongId) {
			this.modalMapAreaInfo = !this.modalMapAreaInfo
			this.areaTitle = `▶ ${title} 통계 요약 (건물평균)`
			this.sigunguId = sigunguId
			this.dongId = dongId
			this.mapType = mapType
			// this.$store.dispatch("main/FETCH_MAP_AREA_DATA", this.mapId);
		},
		onClickEnergyUse(mgmBldPk) {
			// 전역변수 true
			this.buildClickTempInfo = true
			// this.mgmBldPkArr.push(mgmBldPk)

			this.modalEnergyInfo = !this.modalEnergyInfo
			this.mgmBldPk = mgmBldPk
		},
		onClickLasso(mgmBldPkLasso) {
			this.modalLassoInfo = !this.modalLassoInfo
			this.mgmBldPkLasso = mgmBldPkLasso
		},
		onClickCommProc() {
			this.commonEnergy.useYyCd = this.mainSelected.useYyCd.cdId;
			this.commonEnergy.engyKindCd = this.mainSelected.engyKindCd.cdId;
			this.commonEnergy.clsfKindCd = this.mainSelected.clsfKindCd.cdId;
			this.commonEnergy.engyAllQtyCd = this.mainSelected.engyAllQtyCd.cdId;
			this.commonEnergy.engyUse = this.mainSelected.engyUse.cdId;

			if (this.mainSelected.MAIN_PURPS_CD) {
				this.mainSelected.MAIN_PURPS_CD.forEach(item => {
					const temp = {};
					temp.prupsCd = item.cdId;

					if (!this.purpsArr.includes(item.cdId)) {
						this.purpsArr.push(item.cdId);
					}
					this.commonEnergy.prupsCd = this.purpsArr
				});
			}

			console.log(JSON.stringify(this.commonEnergy))
		},
	},

}
</script>

<style>
.container {
  width: 100% !important;
  margin: 0;
  padding: 0;
  max-width: none !important;
}
</style>
