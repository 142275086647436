<template>
  <div
    class="customizer d-none d-md-block"
    :class="{'open': isCustomizerOpen}"
  >
    <!-- Toggler -->
    <b-link
      class="customizer-toggle d-flex align-items-center justify-content-center"
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <feather-icon
        :icon="isCustomizerOpen === true ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        size="15"
      />
    </b-link>

    <b-row>
      <b-col cols="12">
        <b-table-simple
          class="table-style"
          small
          responsive
          class-name="row-style"
        >
          <colgroup>
            <col>
            <col>
          </colgroup>
          <b-thead head-variant="light">
            <b-tr>
              <b-th> 에너지 소비량</b-th>
              <b-th> 건물정보</b-th>
              <b-th> 건물명</b-th>
              <b-th> 시도</b-th>
              <b-th> 시군구</b-th>
              <b-th> 동</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="val in items"
              :key="val.mgmBldPk"
            >
              <b-td>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="parentEnergyUse(val.mgm_bld_pk)"
                >
                  확인
                </b-button>
              </b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  @click="parentBuildInfo(val.mgm_bld_pk)"
                >
                  확인
                </b-button>
              </b-td>
              <b-td>
                {{ val.bld_nm }}
              </b-td>
              <b-td>
                {{ val.sido_nm }}
              </b-td>
              <b-td>
                {{ val.sigungu_nm }}
              </b-td>
              <b-td>
                {{ val.dong_nm }}
              </b-td>

            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
    <!-- /Toggler -->

  </div>
</template>

<script>
import {
  BLink, BButton, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol
} from 'bootstrap-vue'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    // BSV
    BLink,
    BButton,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BRow,
    BCol
  },
  directives: {
    Ripple,
  },
  props: {
    mgmBldPk: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      isCustomizerOpen: false,

      items: [],
    };
  },
  computed: {
    checkMgmBldPk() {
      return this.mgmBldPk;
    },

  },
  watch: {
    checkMgmBldPk(val) {
      this.mgmBldPk = val
      this.$store.dispatch("main/FETCH_TEMP_BUILD_INFO_DATA", {
        dataCode: "tempBuildInfo",
        mgmBldPk: this.mgmBldPk
      }).then(() => {
        this.$store.state.main.tempBuildInfo.forEach((element) => {
          const temp = {};
          temp.bld_nm = element.bld_nm;
          temp.sido_nm = element.sido_nm;
          temp.sigungu_nm = element.sigungu_nm;
          temp.dong_nm = element.dong_nm;
          temp.mgm_bld_pk = element.mgm_bld_pk;
          this.items.unshift(temp)
        })
      }).catch(() => {
        console.error('오류')
      })
    },

  },
  mounted() {

  },
  created() {
    this.$store.dispatch("main/FETCH_TEMP_BUILD_INFO_DATA", {
      dataCode: "tempBuildInfo",
      mgmBldPk: this.mgmBldPk
    }).then(() => {
      this.items = this.$store.state.main.tempBuildInfo;
    }).catch(() => {
      console.error('오류')
    })
  },
  methods: {
    parentEnergyUse(mgmBldPk) {
      this.isCustomizerOpen = !this.isCustomizerOpen
      this.$parent.onClickEnergyUse(mgmBldPk);
    },
    parentBuildInfo(mgmBldPk) {
      this.isCustomizerOpen = !this.isCustomizerOpen
      this.$parent.onClickBuildInfo(mgmBldPk);
    },

  },

}
</script>

<style scoped>
.table-style {
  position: relative;
  white-space: nowrap;
  padding: 5px
}

.customizer .customizer-toggle {
  color: #fff !important;
  display: block;
  position: absolute;
  top: 60%;
  width: 30px;
  height: 30px;
  line-height: 40px;
  left: -31px;
}

</style>
